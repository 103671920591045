import { FC, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import CropUtils from '../../../utils/CropUtils';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';

type CropImageProps = {
  // Either URL OR File needed
  url?: string;
  file?: File;
  open: boolean;
  onClose: () => void;
  onCropped: (file: File) => void;
  confirmButtonTitle?: string;
  maxDimension?: number;
};

const CropImageModal: FC<CropImageProps> = (props) => {
  const { url, file, open, onClose, onCropped, confirmButtonTitle, maxDimension } = props;
  const { t } = useTranslation('common');

  if (!url && !file) {
    throw new Error('URL or FILE needs to be provided');
  }

  const [imageSrc, setImageSrc] = useState(url);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [areaPixels, setAreaPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });

  useEffect(() => {
    if (!imageSrc) {
      setImageSrc(URL.createObjectURL(file as File));
    }

    return () => {
      if (imageSrc?.startsWith('blob:')) {
        URL.revokeObjectURL(imageSrc);
        setImageSrc(undefined);
      }
    };
  }, [file, imageSrc]);

  const doCrop = () => {
    if (!imageSrc) {
      return;
    }

    CropUtils(file?.name || 'CroppedImage', imageSrc, areaPixels, undefined, maxDimension).then((file) => {
      onCropped(file);
      onClose();
    });
  };

  return (
    <div>
      <ModalContext.Provider value={{ open: open, modalWidth: 'w-2/5', onClose: onClose }}>
        <StandardModal
          cancelButtonTitle={t('image-crop.buttons.cancel')}
          confirmButtonTitle={confirmButtonTitle ? confirmButtonTitle : t('image-crop.buttons.crop')}
          onCancelClick={onClose}
          onConfirmClick={doCrop}
        >
          <div className="min-h-128 relative">
            {imageSrc && (
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                zoomSpeed={0.2}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={(area, areaPixels) => setAreaPixels(areaPixels)}
                onZoomChange={setZoom}
              />
            )}
          </div>
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default CropImageModal;
