import { FC, useMemo, useState } from 'react';
import User from '../../../models/User';
import Tooltip from '../Tooltip';
import ProfileInitials from './ProfileInitials';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { useTranslation } from 'react-i18next';
import { ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';
import useAvatarRoleNames from '../../../hooks/useAvatarRoleNames';
import EditIcon from '../icon/EditIcon';
import ImageUploadModal from '../file-upload/ImageUploadModal';
import UploadedImage from '../file-upload/UploadedImage';
import ContextMenu from '../ContextMenu';

export type AvatarRole = {
  role: ClientFormUserRoleValues;
  requiresAction: boolean;
  roleOrder: number | undefined | null;
  isStepRole: boolean;
};

type AvatarUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'userImageId'>;

type AvatarProps = {
  user?: AvatarUser;
  roles?: AvatarRole | AvatarRole[];
  size?: ImageSize;
  withMargin?: boolean;
  className?: string;
  allowEdit?: boolean;
  onImageChanged?: (imageId: string) => void;
};

export enum ImageSize {
  XS,
  S,
  M,
  L,
}

type AvatarSize = {
  initials: string;
  image: string;
};

const ProfileAvatar: FC<AvatarProps> = (props) => {
  const { user, size, withMargin, className = '', roles, allowEdit, onImageChanged } = props;
  let sizeCss: AvatarSize = { initials: 'w-10 h-10 text-dpm-14', image: 'w-42 h-42 text-dpm-14' };
  const marginCss = withMargin ? '-ml-2' : '';
  const { t } = useTranslation(['common']);

  switch (size) {
    case ImageSize.XS:
      sizeCss = { image: 'min-w-[26px] w-[26px] h-[26px] text-xs', initials: 'min-w-6 w-6 h-6 text-xs' };
      break;
    case ImageSize.S:
      sizeCss = { image: 'min-w-[34px] w-[34px] h-[34px] text-dpm-12', initials: 'min-w-8 w-8 h-8 text-dpm-12' };
      break;
    case ImageSize.L:
      sizeCss = { image: 'min-w-[66px] w-[66px] h-[66px] text-2xl', initials: 'min-w-16 w-16 h-16 text-2xl' };
      break;
    default:
      sizeCss = { image: 'min-w-[42px] w-[42px] h-[42px] text-dpm-14', initials: 'min-w-10 w-10 h-10 text-dpm-14' };
      break;
  }

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const roleNames = useAvatarRoleNames(roles || [], t);

  const tooltipText = useMemo(() => {
    return allowEdit
      ? t('common:profile.edit-profile-image')
      : user?.firstName && user?.lastName && (
          <>
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-gray-3">{roleNames}</div>
          </>
        );
  }, [allowEdit, t, user?.firstName, user?.lastName, roleNames]);

  return (
    <div className="relative flex w-fit flex-col justify-center">
      <Tooltip text={tooltipText}>
        {(tooltip) => (
          <div {...tooltip}>
            {!user?.userImageId ? (
              <ProfileInitials
                firstName={user?.firstName ?? ''}
                lastName={user?.lastName ?? ''}
                id={user?.id ?? ''}
                className={className}
                marginCss={marginCss}
                sizeCss={sizeCss.initials}
                {...dataAttributeProps(props)}
              />
            ) : (
              <UploadedImage
                fileId={user.userImageId}
                className={`rounded-full ${size === ImageSize.XS ? 'p-[1px]' : 'p-[2px]'} ${sizeCss.image} ${className} hover:bg-accent-4 rounded-full bg-white shadow-xl transition-all duration-300 ease-in-out hover:z-20 [&:is([data-user-count]):not([data-user-count="0"]):not([data-user-count="1"])]:hover:-mt-[0.3rem] ${marginCss}`}
                {...dataAttributeProps(props)}
                placeholder={
                  <ProfileInitials
                    firstName={user?.firstName ?? ''}
                    lastName={user?.lastName ?? ''}
                    id={user?.id ?? ''}
                    className={className}
                    marginCss={marginCss}
                    sizeCss={sizeCss.initials}
                    {...dataAttributeProps(props)}
                  />
                }
              />
            )}
          </div>
        )}
      </Tooltip>
      {allowEdit && (
        <div className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-30 opacity-0 transition-opacity duration-300 hover:opacity-100">
          {user?.userImageId ? (
            <ContextMenu
              items={[
                {
                  title: t('common:profile.image-menu.remove'),
                  onClick: () => onImageChanged && onImageChanged(''),
                },
                {
                  title: t('common:profile.image-menu.upload'),
                  onClick: () => setUploadModalOpen(true),
                },
              ]}
              type="icon"
            >
              {(ref) => (
                <Tooltip text={tooltipText}>
                  {(tooltip) => (
                    <div {...tooltip} {...ref}>
                      <EditIcon className="h-6 w-6 text-white" />
                    </div>
                  )}
                </Tooltip>
              )}
            </ContextMenu>
          ) : (
            <Tooltip text={tooltipText}>
              {(tooltip) => (
                <div {...tooltip}>
                  <EditIcon className="h-6 w-6 text-white" onClick={() => setUploadModalOpen(true)} />
                </div>
              )}
            </Tooltip>
          )}

          <ImageUploadModal
            title={t('common:profile.image-upload.title')}
            description={t('common:profile.image-upload.description')}
            open={uploadModalOpen}
            onCancel={() => setUploadModalOpen(false)}
            onImageChanged={(id: string) => onImageChanged && onImageChanged(id)}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
